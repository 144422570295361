<template>
  <div id="newsStoryContent">
    <p id="Text1">
      <!-- pic2CF8DB9255BF8016C51F8034FA4F435E.jpg -->
      <img style="height: 265px; margin: 8px 0px;" class="rounded" src="@/assets/news-network/new-creatures-coming-to-comic-con.jpg">
      <br>
      Comic-Con is the largest comic book convention in the world. The event showcases everything
      from comic books and science fiction to film, television, animation, toys and pop culture,
      including video games. It’s therefore the perfect place for LEGO® Universe to bring to life
      some of your inspiring creations!
      <br><br>
      On Sunday, July 25, creative minds behind LEGO Universe will take to the stage to give
      a special Comic-Con presentation! We recently posted a pet-building challenge on
      LEGOuniverse.com, and the development team was so impressed with the submissions that
      they have selected some to be featured in the stage demonstration.
      <br><br>
      <i>Some sample creations from the Build a Pet Challenge:</i>
      <br><br>
      <router-link to="/community/creation-lab/1f26e0da-d457-4b68-ac06-ba5053baa5ab/df4be9b4-0731-4e8c-bc8a-66afc43598a8" class="rounded">
        <!-- pic848A3EFC6C9CC00325A96F96D947A1E6.jpg -->
        <img alt="Raptor By senjaz" style="width: 220px; height: 215px;" src="@/assets/news-network/creation-lab-raptor-by-senjaz.jpg">
      </router-link>
      &nbsp;
      <router-link to="/community/creation-lab/1f26e0da-d457-4b68-ac06-ba5053baa5ab/31ebd753-537f-4636-872a-7a09066758a5" class="rounded">
        <!-- pic099648D3AE47B1D6533DE61833631087.jpg -->
        <img alt="Blue Frog by MFROG5" style="width: 220px; height: 129px;" src="@/assets/news-network/creation-lab-blue-frog-by-mfrog5.jpg">
      </router-link>
      <br><br>
      The presentation will cover how LEGO fans have been involved with the development process,
      and will include a participant from the LUP (LEGO Universe Partners) program; a group of
      adult fans of LEGO helping to build content for the game. The creatures from the Creation Lab
      <router-link to="/community/creation-lab/1f26e0da-d457-4b68-ac06-ba5053baa5ab">
        Build a Pet challenge
      </router-link>
      will then be incorporated into the live demo to show pets imagined and created by real
      fans—and possibly by YOU—come to life in a unique Comic-Con world.
      <br><br>
      Keep checking back here for more news about LEGO Universe at Comic-Con, and find out if
      your pet was chosen!
    </p>
  </div>
</template>
